

@font-face {
  font-family:Thin;
  src:url(./fonts/Champagne&LimousinesBold.ttf) ;
 font-weight: 500;

}

@font-face {
  font-family:Regular;
  src:url(./fonts/Champagne&Limousines.ttf) ;
 font-weight: 500;

}

body {
  font-family: Thin;
  overflow-x : hidden
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E1AD89;
  border-radius: 48px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 48px;
}

.Header {
  background: #E1AD89;
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
}

.Header{
  display: inherit !important;
  direction: ltr !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0 0 0px;
}

.bg-light {
  background-color: #e1ad89!important;
  float: right;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #606060;
}

.SuccessOrder {
  color:#606060;
  text-decoration: none
}

.SuccessOrder:hover {
  color:#E1AD89; 
  text-decoration: none
}

.Logo img {
  width: auto;
  height: 44px;
  margin-top: 5px;
}

.LoginDiv {
  background: #606060;
  padding: 6px 13px;
  border-radius: 2px;
  margin-top: -10px;
  border: 2px solid #606060;
}
.LoginDiv:hover {
  background: transparent;
  transition: 0.5s
}

.LoginDiv img {
  width: auto;
  height: 15px;
  margin-right: 6px;
  margin-top: -4px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0px;
  padding-left: .5rem;
  padding-left: 19px;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1);
  position: absolute;
  right: 0;
  top: 9px;
}

.CartHerder span {
  position: absolute;
  top: 0;
  background: #606060;
  width: 20px;
  height: 20px;
  font-size: 11px;
  text-align: center;
  border-radius: 50%;
  padding-top: 2px;
  color: #fff !important;
  margin-left: -6px;
}
.dropdown-menu {
  background-color: #F5E7DA;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #606060;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #E1AD89;
}

.dropdown-item:last-child {
  border-bottom: none;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #e1ad89;
  text-decoration: none;
  background-color: #F5E7DA;
}


/*** Home ***/
.BodyImage {
  background: url(/img/bg6-01.png) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 669px;
}
.kep-login-facebook {
  width: 100% !important;
  margin-bottom: 45px !important;
  padding: 11px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.About p , .Contact p {
  line-height: 1.4;
  margin-bottom: 0;
}
.LocationSelect {
  text-align: center;
  padding-top:50px;
  padding-bottom: 50px
}



.CategoryHome {
  list-style: none;
    padding: 0;
    margin-top: 12px;
    margin-bottom: 19px;
}

.CostMsg {
  text-align: center;
  color: #606060;
}

.CostMsgError {
  text-align: center;
  color: red;
}
.CategoryHome li {
  margin-bottom: 5px;
  width: 49%;
  background: #e1ad89;
  padding: 5px;
  display: inline-block;
  margin-right: 1%;
  text-align: center
}

.CategoryHome a {
  color: #ffffff;
  text-decoration: none;

}

.CategoryHome a:hover {
  color: #606060;
}

.LocationSelect h1 {
  color: #e1ad89;
  margin-top: 20px;
  margin-bottom: 59px;
}

.css-3rj0br-ReactDropdownSelect {
  padding-left: 17px  !important;
  text-align: left;
  background: #ffffff;
}
.css-jznujr-ContentComponent {
  padding-left: 17px;
  margin-top: -2px;
  
}
.css-c92nab-DropDown {
  text-align: left;
  padding-left: 24px;
}

.no_pad {
  padding: 0
}

.SelectDiv {
  background: rgba(225, 173, 137, 0.30196078431372547);
  padding: 11px;
  border-radius: 6px;
  padding-bottom: 18px;
  margin-bottom: 13px;
}

.SelectDiv img {
  position: absolute;
  left: 14px;
  margin-top: 8px;
  width: auto;
  height: 20px;
  z-index: 9999;
}

.TitleSelectDiv {
  text-align: left;
  color: #606060;
  font-size: 18px;
  margin-bottom: 13px;
  font-family: 'Regular';
}

.LocationForm {
  display: inline-flex;
}

.OrderBut {
  background: #606060;
  color: #fff !important;
  cursor: pointer;
}

.ButtonOrder {
  margin-top: 0px;
  background: #606060;
  padding-top: 3px;
  border: 2px solid #606060;
}
.ButtonOrder:hover .OrderBut {
  background: #fff; 
  color: #606060 !important;
  text-decoration: none;
  transition: 0.5s
}

.ButtonOrder:hover {
  background: #fff; 
  color: #606060;
  text-decoration: none;
  transition: 0.5s
}

.CakeHome {
  text-align: center;
  margin-bottom: 64px;
}
 

.css-1ewbqoa-DropDown {
  background: #f6e6dbf2 !important;
}

/**** Footer ****/

.Footer {
  background: url(/img/JaDoughPicture.jpg) no-repeat;
  background-position: bottom;
  background-size: cover;
  
}
.Desserts h2{
  font-size: 22px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0;
  color: #606060;
}

.FooterLayer {
  background: rgba(57, 54, 54, 0.34);
  padding-top: 45px;
  padding-bottom: 37px;
}

.FooterLayer h3 {
  color: #fff;
  padding-top: 12px;
  margin-bottom: 1px;
  margin-bottom: -14px;
  font-size: 24px;
}

.Gallery h3 {
    padding-top: 0;
    margin-bottom: 11px;
}
.FooterLayer p {
  color: #fff;
  padding-top: 7px;
  line-height: 1.3;
}

.Footer img {
  padding-top: 28px;
}

.Footer .btn-outline-secondary {
  color: #fff;
  border-color: #606060;
  background: #606060;
}
.Footer .btn-outline-secondary:hover{
  color: #606060; 
  background: #fff;
}

 
.Footer ul {
  list-style: none;
  color: #ffffff;
  padding: 0;
  margin-top: 1px;
  padding-left: 43px
}

.Footer ul li { 
  margin-bottom: 12px
}

.Footer ul li a{ 
  color: #fff; 
  text-decoration: none; 
}

.Footer ul li a:hover{ 
  color: #6c757d; 
  text-decoration: none; 
}

.Footer ol {
  list-style: none;
  margin-top: 32px;
  display: -webkit-inline-box;
  padding: 0;
  margin-right: -23px;
}

.Footer ol li {
  margin-bottom: 23px;
  margin-right: 7px;
}

.Footer .SocialMediaFooter {
  text-align: right;
}

.Footer ol li a {
  background: #606060;
  padding: 8px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #606060;
}

.Footer ol li a:hover {
  background: #fff; 
  color: #606060; 
  transition: 0.5s
}

.Footer ol li .fab {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
}

.Footer h5 {
  border-top: 1px solid #fff;
  margin-top: 204px;
  padding-top: 8px;
  color: #fff;
}

.Social ol {
  list-style: none;
  margin-top: 18px;
  display: -webkit-inline-box;
  padding: 0
}

.Social ol li {
  margin-bottom: 23px;
  margin-right: 12px;
}

.Social ol li a {
  background: #606060;
  padding: 8px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #606060;
}

.Social ol li a:hover {
  background: #fff; 
  color: #606060; 
  transition: 0.5s
}

.Social ol li .fab {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
}

/** Desserts */
.Desserts h1 {
  text-align: center;
  color: #606060;
  margin-top: 50px;
  margin-bottom: 50px;
}

.card {
   
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.14);
  margin-bottom: 29px;
}
.card a {
  color: #606060;
  text-decoration: none;
}
.Desserts .card-body { 
  text-align: center;
}
.Desserts .card-title {
  margin-bottom: 11px;
  font-size: 18px;
  height: 29px;
}

.Desserts .card-text {
  margin-bottom: 17px;
  margin-top: 23px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.1;
  height: 36px;
}

.Desserts h3 {
  color: #e1ad89;
  font-size: 18px;
  margin-bottom: 0px;
}

.css-w3djcp-OptionComponent { 
  background: #ffffff !important;
  color: #e1ad89 !important; 
}
 
.image-container {
  display: block; max-width: 100%; height: auto; 
  
 }

.image-container .SlideShow {
  height: 500px; 
  width: 500px;
  border-radius: 50%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  box-shadow: -1px 10px 26px #00000052;
  margin-bottom: 30px;
  margin-top: 30px;
}


 .ProductIMG {
  height: 250px; 
  width: 100%; 
  padding: 0; 
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}


.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  height: 250px;
}

.Pagination {
  text-align: center;
  margin-bottom: 50px;
}

#page-numbers {
  list-style: none;
  display: flex;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: #e1ad89;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid;
  width: 26px;
}

#page-numbers .active {
  color: #606060;
}
#page-numbers   {
  padding: 0;
  display: inline-flex;
}

.card:hover {
  transform: scale(1.1);
  transition: 0.5s
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; 
}

.DessertsDet {
  padding-top: 50px;
  padding-bottom: 50px
}

.DessertsDet h3 {
  color: #E1AD89;
  margin-bottom: 30px;
}

.DessertsDet img {
  width: 100%;
  max-height: 385px;
}

.PriceRange {
  margin-top: 37px;
  display: -webkit-inline-box;
}

.QntMob {
  position: absolute;
  right: 22px;
}
.QntMob  button {
  background: none;
  border: none;
  outline: none !important
}
.QntMob input {
  border: none;
    text-align: center;
}

.OrderNOw {
  background: #606060;
  padding: 8px 22px;
  color: #fff !important;
  border-radius: 4px;
  text-decoration: none;
  border: 2px solid #606060;
  cursor: pointer;
}
.OrderNOw:hover {
  background: #fff; 
  color: #606060 !important; 
  text-decoration: none;
  transition : 0.5s
}


.OrderLater {
  background: #606060;
  padding: 5px 22px;
  color: #fff !important;
  border-radius: 4px;
  text-decoration: none;
  border: 2px solid #606060;
  cursor: pointer;
}
.OrderLater:hover {
  background: #fff; 
  color: #606060 !important; 
  text-decoration: none;
  transition : 0.5s
}

.OrderNOwBack {
  background: #E1AD89;
  padding: 8px 22px;
  color: #fff !important;
  border-radius: 4px;
  text-decoration: none;
  border: 2px solid #E1AD89;
  cursor: pointer;
  margin-top: -5px;
  margin-right: 8px;
}
.OrderNOwBack:hover {
  background: #fff; 
  color: #E1AD89 !important; 
  text-decoration: none;
  transition : 0.5s;
  border: 2px solid #E1AD89 !important;
}

.OrderNOwContainer {
  text-align: center;
  margin-top: 51px;
  margin-bottom: 57px;
}

.number-input input[type="number"] {
  -webkit-appearance: textfield;
    -moz-appearance: textfield;
          appearance: textfield;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.SuccessMsg {
  text-align: center;
}
.react-datepicker-wrapper .DatePic{
  width: 100%;
  border-radius: 4px;
  border: 1px solid #60606070;
  padding: 6px 8px;
  outline: none;
}

.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-input {
  margin-bottom: 3rem;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type=number] {
  text-align: center;
}
.number-input.number-input {
  border:none;
  width: 10rem;
  border-radius: .25rem;
}
.number-input.number-input button {
  width: 2.6rem;
  height: .7rem;
}
.number-input.number-input button.minus {
  padding-left: 10px;
}
.number-input.number-input button:before,
.number-input.number-input button:after {
  width: .7rem;
  background-color: #495057;
}
.number-input.number-input input[type=number] {
  max-width: 4rem;
  padding: .5rem;
  border: none;
  border-width: 0 1px;
  font-size: 1rem;
  height: 2rem;
  background: none;
  color: #495057;
  outline: none!important;
}


.Filters  { 
}

.SuccessPage {
  text-align: center;
}


.card-text p:first-letter , .DessertsDet  p:first-letter {
  text-transform: uppercase
}
.Filters textarea.form-control {
  height: auto;
  margin: 8px;
  width: 96%;
  margin-top: 17px;
  margin-bottom: 19px;
}

.Filters h4 {
  font-size: 22px;
  background : #E1AD89;
  padding : 10px;
  margin: 0;
  color : #fff
}

.Filters h5 {
    background: #F5F5F5;
    color: #606060;
    font-size: 16px;
    padding: 10px;
    margin: 0;
}
.Filters h5 span {
    font-family: 'Regular';
    font-size: 15px;
}
.containerRadio {
  display: inline-block;
  position: relative;
  padding-left: 29px;
  cursor: pointer;
  font-size: 17px;
  width: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right: 0;
  line-height: 1.5;
  margin-bottom: 13px;
}
.gusetLog {
  margin-top: 23px;
  margin-bottom: 24px;
  font-size: 21px;
}
/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .checkmark {
    background-color: #e1ad89;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .checkmark:after {
    top: 6px;
    left: 6px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
}
.RadioForm {    
  display: -webkit-box;
  margin-top: 20px;
  margin-bottom: 13px;
}

.modal-footer .btn-primary {
  color: #fff !important;
  background-color: #606060;
  border-color: #606060;
  cursor: pointer;
}

.modal-footer .btn-primary:hover {
    color: #606060 !important;
    background-color: #fff;
    transition : 0.5s
}

.modal-footer .btn-secondary {
    color: #fff !important;
    background-color: #E1AD89;
    border-color: #E1AD89;
    cursor : pointer
}

.modal-footer .btn-secondary:hover {
    color: #E1AD89 !important;
    background-color: #fff; 
}

.OrderNOwContainerProduct {
    text-align: left;
    margin-top: 51px;
    margin-bottom: 57px;
    padding: 0;
}


.MobileImage {
  display : none
}

.modal { 
  z-index: 9999999999999999 !important;
}

.slider {
  width: 100%;
}

input[type="range"] {
    -webkit-appearance: none !important;
    width: 100%;
    height: 5px;
    background-color: #e1ad89;
    border: 1px solid #e1ad89;
    border-radius: 10px;
    margin: auto;
    transition: all 0.3s ease;
    outline: none !important;
}

input[type="range"]:hover {
    background-color: #606060;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 20px;
  height: 20px;
  background-color: #e1ad89;
  border-radius: 30px;
  box-shadow: 0px 0px 3px #e1ad89;
  transition: all 0.5s ease;
}
input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #e1ad89;
}
input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: 0px 0px 1px #e1ad89;
}

#rangevalue {
  text-align: center; 
  font-size: 18px;
  display: block;
  margin: auto;
  padding: 10px 0px;
  width: 100%;
  color: #e1ad89;
}

  
 .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: none;
}

.table img {
  width: auto;
  height: 79px;
}

.table .Delete {
  text-align : center
}

.table .Delete a {
  color: #606060;
  cursor: pointer;
}
.RightCol {
  text-align: right
}

.table .Delete a:hover {
  color: #e1ad89;
}
.Contact {
  background: #fff;
  padding: 37px;
  box-shadow: 0 0 8px #0000000f;
  padding-bottom: 43px; 
}

.Contact a {
  color: #e1ad89; 
}

.Contact a:hover {
  color: #606060; 
}

.Contact ul {
    list-style: none;
    padding: 0;
    margin : 0
}

.Contact ul li i{ 
  padding-right: 14px;
  color: #E1AD89
}

.Contact ul li {
  margin-bottom: 18px;
  display: inline-flex;
  padding-right: 7%;
}

.Contact h3 {
  color: #E1AD89;
  margin-bottom: 18px;
  margin-top: 39px;
}

.BackButt {
  color : #606060
}
.BackButt:hover {
  color : #E1AD89
}

.TotalTable h5 {
    font-size: 17px;
    float: right;
}

.map {
  width: 84% !important;
}
.Gallery .ReactGridGallery_tile-viewport {
  width: auto !important;
  height: auto  !important;
}

 .Gallery .ReactGridGallery_tile-viewport img {
  width: 109px !important;
  height: 84px  !important;
}

.MapClass {
      position: unset !important; 
}

.GalleryPage { 
  margin-bottom: 90px
}

.GalleryPage .ReactGridGallery_tile-viewport img {
  width: inherit !important;
  height: 100%  !important;
}

.MapContact {
    display: -webkit-box;
    padding: 0;
    margin-top: 40px;
}

.image-element-class {
  display: -webkit-inline-box;
  width: 33.33%;
  height: auto;
}

.image-element-class  img{
  width: 100%;
}

.SpecialOrder h1 {
    text-align: center;
    font-size: 24px;
    color: #E1AD89;
    margin-bottom: 25px;
}

.SpecialOrder {
  background: #fff;
  padding: 40px;
  box-shadow: 0 3px 11px #0000001a;
  margin-bottom: 43px;
  margin-top: 40px;
  text-align: center;
}

.SpecialOrder  label {
    display: inline-block;
    margin-bottom: .5rem;
    text-align: left;
    float: left;
}

.SpecialOrder .btn-primary {
    color: #fff;
    background-color: #606060;
    border-color: #606060;
    margin-top: 29px;
    width: 200px;
}

.CartButtons {
  text-align: center;
  padding-bottom: 30px;
}

.CartButtons .btn-primary {
  color: #fff;
  background-color: #e1ad89;
  border-color: #e1ad89;
  margin-left: 10px;

}

.CartButtons .btn-secondary:hover {
  color: #6c757d;
  background-color: #fff; 
  transition: 0.5s;
}

.image-element-class img {
  width: 100%;
  height: 390px;
  padding: 6px;
}

.CustomizedCake {
  color: #E1AD89;
  text-decoration: none;
}

.CustomizedCake:hover {
  color: #606060;
  text-decoration: none;
  transition: 0.5s;
}

.CartButtons .btn-primary:hover{
  color: #e1ad89;
  background-color: #fff; 
  transition: 0.5s;
}

.CloseCart {
  position: absolute;
    right: 9px;
    top: 6px;
    color: #fff !important;
    padding: 4px;
    cursor: pointer;
}

.Login .btn-primary {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
  margin-top: 29px;
  width: 100%;
}

.Signup .btn-primary {
  color: #fff;
  background-color: #606060;
  border-color: #606060;
  margin-top: 29px;
  width: 200px;
}
.SpecialOrder  .btn-primary:hover {
    color: #606060;
    background-color: #fff; 
    transition : 0.5s
}

.lightbox-container img {
  margin : 2px;
  width: 69px !important;
  height: 69px !important;
}

.CreateAcc {
  margin-top: 24px;
}

.CreateAcc a {
  color: #606060;
  text-decoration : none
}

.CreateAcc a:hover {
  color: #E1AD89;
}

.CreateAcc .forgetPass{
  color: #606060 !important;
  cursor: pointer;
}

.CreateAcc .forgetPass:hover{
  color: #E1AD89 !important;
}

.Login {
  margin-top : 90px
}

.QuantityTable {
  padding-left: 5% !important;
}

.rangeslider-horizontal .rangeslider__fill { 
  background-color: #e1ad89 !important;  
}
.rangeslider .rangeslider__handle {
  background: #e1ad89;
  border: 1px solid #e1ad89;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
  outline: none !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 18px;
  height: 18px; 
}

.rangeslider-horizontal {
  height: 8px;
  border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__handle:after {
  display: none
}

.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e1ad89;
  touch-action: none;
}

/** Loader*/ 
.Loader {
  background: #fcfcfc;
  z-index: 9999999;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  padding-top: 10%;
}
 
.Loader img {
  width: 300px
}

 
.Footer a {
  color: #fff;
  text-decoration: none
}

.Footer a:hover {
  color: #E1AD89;
  text-decoration: none
}

.Events {
  margin-bottom: 44px;
}
.Events h1 {
  text-align: left;
  color: #E1AD89;
  margin-top: 52px;
  margin-bottom: 18px;
}

.EventCard {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 6px #00000014;
  padding: 12px;
  margin-bottom: 20px;
}

.EventCard h2 {
  text-align: left;
  color: #E1AD89;
  margin-top: 8px;
  margin-bottom: 13px;
}

.OtherInput {
  margin-top: 5px;
  padding-left: 0;
  padding-right: 0;
}

.CheckoutButt .OrderNOwBack {
  padding: 4px 12px;
}

.Events  strong { 
  color: #e1ad89;
}
/************* Laptop *************/
@media screen  and (min-device-width: 1200px)  and (max-device-width: 1600px)   and (-webkit-min-device-pixel-ratio: 1) { 
 

}
/************* Laptop & Taplet *************/
@media (min-width:992px )and (max-width:1200px){
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 3px;
}

.CartHerder span { 
  margin-left: -17px;
}

  .ReactGridGallery_tile-viewport img {
    width: 105px !important;
    height: 84px !important;
}
.Contact ul li { 
  padding-right: 2%;
}

.image-element-class {
  width: 33.33% !important;
  /*
  left: 0 !important;*/
}

}
/************** Taplet ************/
@media (min-width:768px )and (max-width:991px){

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    top: 53px;
    background: #e1ad89;
    width: 100%;
    z-index: 99999999;
  }
  .CartHerder span {
    top: auto;
    bottom: auto;
    margin-top: -14px;
}
.bg-light { 
  float: inherit;
}

.LoginDiv { 
  width: 94%;
}
.navbar-expand-lg .navbar-nav .nav-link { 
  padding-left: 16px;
}
.Footer h5 { 
  font-size: 14px;
  margin-top: 294px;
}



.Desserts .card-title { 
  font-size: 15px;
}

.ReactGridGallery_tile-viewport img {
  width: 88px !important;
  height: 84px !important;
}

.image-element-class {
  width: 33.33% !important;/*  left: 0 !important;*/
  height: auto;
}
}

/************** Mobile ************/
@media (max-width:767px){
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    top: 53px;
    background: #e1ad89;
    width: 100%;
    z-index: 9999999999;
  }
  .table img { 
    height: 53px;
}

.image-element-class {
  width: 100%;
  left: 3px !important;
}



.CartButtons .btn-secondary {
  margin-bottom: 15px;
}
.CartButtons .btn-primary { 
  margin-left: 0;
}
.table td, .table th {
    padding: 5px;
    font-size: 13px;
}

.number-input.number-input input[type=number] {
    max-width: 30px;
    padding: 5px; 
    font-size: 15px;
    height: 2rem; 
}
  .CartHerder span {
    top: auto;
    bottom: auto;
    margin-top: -14px;
}
.MobileImage {
  display : inline;
  margin-bottom : 40px
}

.WebImage {
  display : none
}

.LoginDiv { 
  width: 94%;
}
.card:hover {
  transform: scale(1); 
}
.navbar-expand-lg .navbar-nav .nav-link { 
  padding-left: 16px;
}

.LocationForm {
  display: inline-block;
}

.ButtonOrder { 
  margin-top: 8px;
}

.CakeHome img {
  width: 100%;
  height: 200px;
}

.Footer ol { 
  margin-left: -32px;
}

.bg-light { 
  float: inherit;
}

.Footer ul { 
  padding-left: 3px
}

.map {display : none}

.Contact { 
    margin: 18px;
}

.ReactGridGallery_tile-viewport img {
  width: 112px !important;
  height: 84px !important;
}

.Footer .SocialMediaFooter {
  text-align: left;
}
.Footer ol {
  margin-left: 0;
}

.image-container .SlideShow {
  height: 330px;
  width: 330px; 
}
 
}
@media (min-width:578px )and (max-width:766px){
  .OrderBut {
    font-size: 13px;
  }

  .LocationForm {
    display: inline-flex;
}

.map {display : inline}


.ButtonOrder { 
  margin-top: 0;
}

.Gallery {
  display: none;
}

.Desserts .card-title { 
  font-size: 12px;
}
.Desserts .card-text { 
  font-size: 10px;
}

}

@media (min-width:570px )and (max-width:770px){
  .Gallery {
    display: none;
  }

  .DessertsDet h3 {
    color: #E1AD89;
    margin-bottom: 30px;
    font-size: 15px;
}

.ReactGridGallery_tile-viewport img {
  width: 100px !important;
  height: 84px !important;
}
 
}

@media (max-width:350px){
  .ReactGridGallery_tile-viewport img {
    width: 106px !important;
    height: 84px !important;
}

.image-container .SlideShow {
  height: 290px;
  width: 290px; 
}
}